<template>
    <div class="content-center">
        <div class="container-sm">
            <h2 class="title-center mb40 uppercase">
                {{ $t('auth.resetPasswordTitle') }}
            </h2>
            <div v-on:keyup.enter="onEnterSubmit">
                <label
                    class="pure-material-textfield-outlined"
                    :class="{
                        error:
                            ($v.formData.password.$invalid &&
                                !$v.formData.password.minLength) ||
                            notSame
                    }"
                >
                    <input
                        placeholder=" "
                        type="password"
                        v-model="formData.password"
                        @blur="$v.formData.password.$touch()"
                        required
                    />
                    <span>{{ $t('global.password') }}</span>
                </label>

                <label
                    class="pure-material-textfield-outlined"
                    :class="{
                        error:
                            ($v.formData.confirmPassword.$invalid &&
                                !$v.formData.confirmPassword.minLength) ||
                            notSame
                    }"
                >
                    <input
                        placeholder=" "
                        type="password"
                        v-model="formData.confirmPassword"
                        @blur="$v.formData.confirmPassword.$touch()"
                        required
                    />
                    <span>{{ $t('settings.repeatPassword') }}</span>
                </label>
            </div>

            <div>
                <md-button
                    class=" mt16"
                    :class="{
                        'btn-default-blue': !$v.$invalid,
                        'btn-default-action': $v.$invalid
                    }"
                    style="margin-left:0;"
                    @click="submit"
                    @keyup.enter.native="submitResetPassword"
                    >{{ $t('auth.resetYourPassword') }}</md-button
                >
                <div class="column mt40">
                    <md-button class="c191456" to="/login">{{
                        $t('auth.login')
                    }}</md-button>
                    <md-button
                        href="https://bus-do-polski.pl/account"
                        target="_blank"
                        class="c191456"
                        >{{ $t('auth.createAccount') }}</md-button
                    >
                </div>
            </div>

            <div class="loading-overlay" v-if="loading">
                <div class="wrapper">
                    <md-progress-spinner
                        md-mode="indeterminate"
                        :md-stroke="2"
                    />
                </div>
            </div>
        </div>
        <success-modal
            :show="showSuccessModal"
            :message="$t('settings.passwordHasBeenChanged')"
            @close="
                () => {
                    showSuccessModal = false;
                }
            "
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import SuccessModal from '../../components/modals/successModal';

export default {
    validations: {
        formData: {
            password: {
                required,
                minLength: minLength(8)
            },
            confirmPassword: {
                required,
                minLength: minLength(8)
            }
        }
    },

    components: {
        SuccessModal
    },

    data() {
        return {
            loading: false,
            showSuccessModal: false,
            formData: { password: '', confirmPassword: '' },
            notSame: false
        };
    },

    methods: {
        ...mapActions({
            changePassword: 'auth/changePassword'
        }),

        onEnterSubmit() {
            if (!this.$v.$invalid) {
                this.submitResetPassword();
            } else {
                this.$toast.error(this.$t('global.somethingWrong'));
            }
        },

        submit() {
            if (!this.$v.$invalid) {
                this.submitResetPassword();
            }
        },

        async submitResetPassword() {
            this.loading = true;
            const { password, confirmPassword } = this.formData;

            if (password !== confirmPassword) {
                this.$toast.error(this.$t('settings.passwordsDoNotMatch'));
                this.loading = false;
                return;
            }

            try {
                const { code } = this.$route.params;
                const formData = new FormData();
                formData.append('code', code);
                formData.append('password', this.formData.password);

                const response = await this.changePassword(formData);

                if (response) {
                    this.showSuccessModal = true;

                    setTimeout(() => {
                        this.$router.push({ name: 'login' });
                    }, 2500);
                } else {
                    this.$router.push({ name: 'reset-password' });
                    this.$toast.error(this.$t('auth.passwordAlreadyChanged'));
                }

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$toast.error(this.$t('global.somethingWrong'));
            }
        }
    }
};
</script>
